html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  padding-bottom: 100vh;
  background-color: black;
  color: white;
  -webkit-font-smoothing: antialiased;
  font-family: "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 1;
  overflow: hidden;
  user-select: none;
}

.Lowlight {
  opacity: 0;
}

.Lowlight,
.Highlight {
  letter-spacing: 1px;
  text-transform: uppercase;
}

.Highlight {
  opacity: 1;
}
.Highlight--1 {
  opacity: 1;
  /* Gray 50% */
  color: #7f7f7f;
}
.Highlight--2 {
  opacity: 1;
  /* Gray 25% */
  color: #404040;
}

.Keyboard {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.Keyboard__row {
  display: flex;
  justify-content: center;
}

@keyframes fade {
  100% {
    opacity: 0;
  }
}

.Key {
  position: relative;
  width: 1em;
  margin: 0.0625em;
  text-transform: uppercase;
  opacity: 0;
  text-align: center;
  line-height: 1;
  vertical-align: middle;
  font-size: 300%;
  animation: fade 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}
.Key--active {
  opacity: 1;
}

@media (max-width: 1024px) {
  .Key {
    font-size: 250%;
  }
}

@media (max-width: 800px) {
  .Key {
    font-size: 175%;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 16px;
  }
  .Key {
    font-size: 200%;
  }
}
